<template>
  <section id="ft-about-2" class="ft-about-section-2">
    <div class="container">
      <div class="ft-about-content-2">
        <div class="row">
          <div class="col-lg-6">
            <div class="ft-about-text-wrapper-2">
              <div class="ft-section-title-2 headline pera-content">
                <span class="sub-title">{{ serviceTitle }}</span>
                <h2>
                  We Provide a Reliable
                  <span>Services</span>
                </h2>
                <p>{{ secvicesDesc }}</p>
              </div>
              <div class="ft-about-feature-wrapper-2">
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="ft-about-feature-list-item d-flex align-items-center"
                    >
                      <div
                        class="ft-about-feature-icon d-flex align-items-center justify-content-center"
                      >
                        <i class="fal fa-bullseye-arrow"></i>
                      </div>
                      <div class="ft-about-feature-text headline pera-content">
                        <h3>Our Mission</h3>
                        <p>
                          Provide Transparent Work Process & Approachable 24x7
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div
                      class="ft-about-feature-list-item d-flex align-items-center"
                    >
                      <div
                        class="ft-about-feature-icon d-flex align-items-center justify-content-center"
                      >
                        <i class="fal fa-bullseye-arrow"></i>
                      </div>
                      <div class="ft-about-feature-text headline pera-content">
                        <h3>Our Vision</h3>
                        <p>
                          To build long-term and mutually beneficial relationship
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="ft-about-img-2-wrapper position-relative">
              <!-- n class="ft-about-shape2 position-absolute"><img src="../../../public/assets/imgs/shape/ab-sh2.png" alt=""></span> -->
              <div class="ft-about-img-2">
                <img :src="servicesImage" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from "../../data/data.json";
export default {
  data() {
    return {
      mainData: "",
      routeName: "",
      serviceTitle: "",
      secvicesDesc: "",
      servicesImage: ""
    }
  },
//   watch:{
//     '$route' (to) {
//         if(to) {
//            location.reload()
//         }
//     } 
//   },
  created() {
    this.mainData = data.services.services_cat;
    // for Get Route Name
    this.routeName = this.$route.name;

    // For get Data by route
    if (this.routeName == "Liner Container") { 
        this.serviceTitle = this.mainData[0].title;
        this.secvicesDesc = this.mainData[0].desc;
        this.servicesImage = this.getItem(this.mainData[0].image)
      } 
      // else if (this.routeName == "Tramp Container") { 
      //   this.serviceTitle = this.mainData[1].title;
      //   this.secvicesDesc = this.mainData[1].desc;
      //   this.servicesImage = this.getItem(this.mainData[1].image)
      // } 
      else if (this.routeName == "Reefer Containers") { 
        this.serviceTitle = this.mainData[1].title;
        this.secvicesDesc = this.mainData[1].desc;
        this.servicesImage = this.getItem(this.mainData[1].image)
      } 
      // else if (this.routeName == "Freight Forwarding") { 
      //   this.serviceTitle = this.mainData[3].title;
      //   this.secvicesDesc = this.mainData[3].desc;
      //   this.servicesImage = this.getItem(this.mainData[3].image)
      // } 
      else if (this.routeName == "Container Sale & Purchase") { 
        this.serviceTitle = this.mainData[2].title;
        this.secvicesDesc = this.mainData[2].desc;
        this.servicesImage = this.getItem(this.mainData[2].image)
      } else if (this.routeName == "ISO Tank Containers") { 
        this.serviceTitle = this.mainData[3].title;
        this.secvicesDesc = this.mainData[3].desc;
        this.servicesImage = this.getItem(this.mainData[3].image)
      } else { 
        this.serviceTitle = "";
        this.secvicesDesc = "";
      }
  },
  methods:{
    getItem(pic){
      if(pic) {
        return require("../../../public/assets/imgs/service/" + pic)
      } else {
        return null
      }
    }
  }
//   computed: {
//     callData() {
//         return this.getData();
//     }
//   },
//   methods: {
//     getData() { 
//       if (this.routeName == "Liner Container") {
//         this.serviceTitle = this.mainData[0].title;
//         this.secvicesDesc = this.mainData[0].desc;
//       } else if (this.routeName == "Tramp Container") {
//         this.serviceTitle = this.mainData[1].title;
//         this.secvicesDesc = this.mainData[1].desc;
//       } else if (this.routeName == "Reefer Containers") {
//         this.serviceTitle = this.mainData[2].title;
//         this.secvicesDesc = this.mainData[2].desc;
//       } else if (this.routeName == "Freight Forwarding") {
//         this.serviceTitle = this.mainData[3].title;
//         this.secvicesDesc = this.mainData[3].desc;
//       } else if (this.routeName == "Container Sale & Purchase") {
//         this.serviceTitle = this.mainData[4].title;
//         this.secvicesDesc = this.mainData[4].desc;
//       } else if (this.routeName == "ISO Tank Containers") {
//         this.serviceTitle = this.mainData[5].title;
//         this.secvicesDesc = this.mainData[5].desc;
//       } else {
//         this.serviceTitle = "";
//         this.secvicesDesc = "";
//       }
//     }
//   }
}
</script>
