<template>
    <breadCrumb/>
    <service/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import service from '../components/services/searvicesComon.vue'
export default {
    components:{
        breadCrumb,
        service
    },
    data() {
        return{}
    }
}
</script>